import { Locale } from "apps/freshbuffer/src/core/Intl/types";

import { defineRoute } from "./defineRoute";

export * from "./types";

export const ROUTES = Object.freeze({
  Home: defineRoute("/"),
  AboutUs: defineRoute("/about-us", "/over-ons"),
  ServiceNow: defineRoute("/service-now", "/service-ya"),
  ContactUs: defineRoute("/contact-us", "/contact"),
  GlobalTalentStrategy: defineRoute(
    "/services/global-talent-strategy",
    "/services/global-talent-strategy"
  ),
  // @placeholder:route <= used by FRESHBUFFER-page code generator to inject route definition
});

/** routes that are publicly accessible but changes visually when a user is authenticated */
export const PUBLIC_ROUTES: readonly string[] = Object.freeze([
  ROUTES.Home.pathname,
  ROUTES.AboutUs.pathname,
  ROUTES.ServiceNow.pathname,
  ROUTES.ContactUs.pathname,
  ROUTES.GlobalTalentStrategy.pathname,

  // ...
]);

export const ROUTE_TO_IMPLEMENTATION_MAPPING = Object.freeze(
  Object.fromEntries(
    Object.values(ROUTES).map((v) => [
      v.pathnameByLocale[Locale.nl],
      v.pathname,
    ])
  )
) as Record<string, string>;

export function getRouteDefintionByPathname(pathname: string) {
  for (const entry of Object.entries(ROUTES)) {
    const defintion = entry[1];
    if (defintion.pathname === pathname) {
      return defintion;
    }
  }

  return null;
}
