import { Locale } from "@freshbuffer/core/Intl/types";
import CategoryIcon from '@mui/icons-material/Category';
import { Button } from "@mui/material";
import { useIntl } from "apps/freshbuffer/src/core/Intl";
import { ROUTES } from "apps/freshbuffer/src/core/Routing";
import { NavLink } from "apps/freshbuffer/src/core/Routing/NavLink";
import { CommonStyles } from "apps/freshbuffer/src/styles/CommonStyles";
import classnames from "classnames";
import { useRouter } from "next/router";
import React, { useState } from "react";
import styles from "./HeaderDesktop.module.css";
import { LogoLink } from "./LogoLink";

export const HeaderDesktop = () => {
  const { T, locale } = useIntl();
  const router = useRouter();
  const [isActiveIndex, setIsActiveIndex] = useState(
    getActiveIndex(router.pathname)
  );
  // const [popoverAnchorEl, setPopoverAnchorEl] =
  //   useState<HTMLButtonElement | null>(null);

  const setMenu = (index: number) => {
    setIsActiveIndex(index);
  };
  // const handlePopoverOpenButtonClick = (event: any) =>
  //   setPopoverAnchorEl(event.currentTarget);

  // const handlePopoverClose = () => setPopoverAnchorEl(null);
  // const isPopoverOpen = popoverAnchorEl !== null;

  return (
    <React.Fragment>
      <div className={styles.root}>
        <LogoLink />
        <React.Fragment>
          <NavLink.Native
            underline="none"
            href={ROUTES.GlobalTalentStrategy.url({}, locale)}
            className={classnames(
              styles.navLink,
              isActiveIndex === 1 ? styles.activeLink : ""
            )}
            onClick={() => setMenu(1)}
            // onClick={handlePopoverOpenButtonClick}
          >
            {T("HEADER_SUB_MENU_GLOBAL_TALENT")}
            {/* <IconButton
              className={styles.popoverOpenButton}
              onClick={handlePopoverOpenButtonClick}
            >
              <ChevronDownThinIcon color="secondary" />
            </IconButton> */}
          </NavLink.Native>

          {/* <NavLink.Anchor
            href={ROUTES.ServiceNow.url({}, locale)}
            className={classnames(
              styles.navLink,
              isActiveIndex === 2 ? styles.activeLink : ""
            )}
            underline="none"
            onClick={() => setMenu(2)}
          >
            {T("HEADER_MENU_SERVICE_NOW")}
          </NavLink.Anchor> */}
          {/* <NavLink.Anchor
            href={ROUTES.AboutUs.url({}, locale)}
            className={classnames(
              styles.navLink,
              isActiveIndex === 3 ? styles.activeLink : ""
            )}
            onClick={() => setMenu(3)}
            underline="none"
          >
            {T("HEADER_MENU_ABOUTUS")}
          </NavLink.Anchor> */}
          <NavLink.Anchor
            href="#"
            className={classnames(
              styles.navLink,
              isActiveIndex === 4 ? styles.activeLink : ""
            )}
            onClick={() => setMenu(4)}
            underline="none"
          >
            <CategoryIcon className={ CommonStyles.margin_right_1} style={{ color: '#0071E3'}} />
            {T("HEADER_MENU_PRODUCTS")}
          </NavLink.Anchor>
        </React.Fragment>
      </div>
      <nav className={styles.letUsTalkButton}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={NavLink.Anchor}
          href={ROUTES.ContactUs.url({}, locale)}
        >
          {T("TALK_TO_US")}
        </Button>
      </nav>
      {/* <Popover
        className={ classnames(CommonStyles.popoverBorderRadiusMedium)}
        open={isPopoverOpen}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        <ul className={styles.popoverContent}>
        <li>
            <NavLink.Anchor href={ROUTES.GlobalTalentStrategy.url({}, locale)} underline="none">
              {T("HEADER_SUB_MENU_GLOBAL_TALENT")}
            </NavLink.Anchor>
          </li>
          <li className={styles.drawerDivider} />
          <li>
            <NavLink.Anchor href="#" underline="none">
              {T("HEADER_SUB_MENU_HARVEST_YOUNG_TALENT")}
            </NavLink.Anchor>
          </li>
          <li className={styles.drawerDivider} />
          <li>
            <NavLink.Native href="#" underline="none">
              {T("HEADER_SUB_MENU_SERVICES_WHAT_WE_DO")}
            </NavLink.Native>
          </li>
          <li className={styles.drawerDivider} />
          <li>
            <NavLink.Native href="#" underline="none">
              {T("HEADER_SUB_MENU_SERVICES_IT_CONSULTING")}
            </NavLink.Native>
          </li>
        </ul>
      </Popover> */}
    </React.Fragment>
  );
};

const getActiveIndex = (pathname: string) => {
  switch (pathname) {
    case ROUTES.GlobalTalentStrategy.url({}, Locale.en):
      return 1;
    case ROUTES.AboutUs.url({}, Locale.en):
      return 2;
    case ROUTES.Home.url({}, Locale.en):
      return 0;
    default:
      return 0;
  }
};
