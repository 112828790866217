export interface SocialMediaShareUrls {
  facebookShareUrl: string;
  whatsAppShareUrl: string;
  fbMessengerShareUrl: string;
  emailShareUrl: string;
  copyLinkUrl: string;
  linkedInUrl: string;
}

export const getSocialMediaShareUrls = (
  shareTargetUrl: string,
  isMobile: boolean,
  emailSubject: string,
  emailBody: string
): SocialMediaShareUrls => {
  const facebookAppId = "FACEBOOK_MESSENGER_ID";
  const utmParams = `&utm_medium=${
    isMobile ? "mobile" : "desktop"
  }&utm_source=`;

  const generateFacebookShareUrl = (): string => {
    const baseFacebookUrl = "http://www.facebook.com/sharer.php?u=";
    return `${baseFacebookUrl}${shareTargetUrl}${utmParams}facebook&utm_campaign=share`;
  };

  const whatsAppEncodedUrl = encodeURIComponent(
    `${shareTargetUrl}${utmParams}whatsapp&utm_campaign=share`
  );
  const generateWhatsAppShareUrl = (): string => {
    const baseWhatsAppUrl = isMobile
      ? "whatsapp://"
      : "https://api.whatsapp.com/";
    return `${baseWhatsAppUrl}send?text=${whatsAppEncodedUrl}`;
  };

  const generateFbMessengerShareUrl = (): string => {
    const baseFbMessengerUrl = `fb-messenger://share/?link=${shareTargetUrl}&app_id=${facebookAppId}`;
    const basefbMessgenerDesktopUrl = `http://www.facebook.com/dialog/send?link=${shareTargetUrl}&app_id=${facebookAppId}&redirect_uri=${shareTargetUrl}`;
    return `${
      isMobile ? baseFbMessengerUrl : basefbMessgenerDesktopUrl
    }${utmParams}messenger&utm_campaign=share`;
  };

  const generateEmailShareUrl = (): string => {
    return `mailto:?subject=${emailSubject}&body=${emailBody}${utmParams}email&utm_campaign=share`;
  };

  const copyLinkUrl = `${shareTargetUrl}?${utmParams}copy_link&utm_campaign=share`;

  const linkedInUrl = `https://www.linkedin.com/company/freshbuffer`;
  return {
    facebookShareUrl: generateFacebookShareUrl(),
    whatsAppShareUrl: generateWhatsAppShareUrl(),
    fbMessengerShareUrl: generateFbMessengerShareUrl(),
    emailShareUrl: generateEmailShareUrl(),
    copyLinkUrl,
    linkedInUrl,
  };
};
