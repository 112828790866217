import { Button } from "@mui/material";
import { useIntl } from "apps/freshbuffer/src/core/Intl";

import { NavLink } from "@freshbuffer/core/Routing/NavLink";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useMediaIsDesktop } from "apps/freshbuffer/src/utilities/Media/useMediaIsDesktop";
import { getSocialMediaShareUrls } from "./getSocialmediaUrl";
import Facebook from "./images/facebook.svg";
import Linkedin from "./images/linkedin.svg";
import Messenger from "./images/messenger.svg";
import Whatsapp from "./images/whatsapp.svg";
import styles from "./SocialMediaControls.module.css";

type SocialMediaControlsProps = {
  emailSubject: string;
  emailBody: string;
};

export const SocialMediaControls = ({
  emailSubject,
  emailBody,
}: SocialMediaControlsProps) => {
  const { T } = useIntl();
  const isDesktop = useMediaIsDesktop();
  const {
    facebookShareUrl,
    fbMessengerShareUrl,
    whatsAppShareUrl,

    emailShareUrl,
    linkedInUrl,
  } = getSocialMediaShareUrls(
    "https://www.freshbuffer.com",
    !isDesktop,
    emailSubject,
    emailBody
  );

  return (
    <div className={styles.root}>
      <Button
        className={styles.leftAlignedButton}
        startIcon={<Facebook viewBox="0 0 20 20" />}
        component={NavLink.Anchor}
        href={facebookShareUrl}
        fullWidth
        target="_blank"
      >
        {T("LBL_FACEBOOK")}
      </Button>

      <Button
        className={styles.leftAlignedButton}
        fullWidth
        value={fbMessengerShareUrl}
        startIcon={<Messenger viewBox="0 0 20 20" />}
      >
        {T("LBL_MESSENGER")}
      </Button>
      <Button
        className={styles.leftAlignedButton}
        fullWidth
        value={whatsAppShareUrl}
        startIcon={<Whatsapp viewBox="0 0 20 20" />}
      >
        {T("LBL_WHATSAPP")}
      </Button>

      <Button
        className={styles.leftAlignedButton}
        startIcon={<Linkedin />}
        component={NavLink.Anchor}
        href={linkedInUrl}
        fullWidth
        target="_blank"
      >
        {T("LBL_LINKEDIN")}
      </Button>
      <Button
        className={styles.leftAlignedButton}
        fullWidth
        value={emailShareUrl}
        startIcon={<EmailOutlinedIcon />}
      >
        {T("LBL_REGISTER_EMAIL")}
      </Button>
    </div>
  );
};
